<template>
    <div id="team" class="solutions-area section-width ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>{{ $t('navbar.menu_5_1') }}</h2>
            </div>
                <div class="single-privacy">
                    <h3>{{ $t('section_1.title') }}</h3>
                    <span>{{ $t('section_1.content') }}</span>
                </div> 
                 <div class="single-privacy">
                    <h3>{{ $t('section_2.title') }}</h3>
                    <span>{{ $t('section_2.content') }}</span>
                </div>    
                 <div class="single-privacy">
                    <h3>{{ $t('section_3.title') }}</h3>
                    <span>{{ $t('section_3.content') }}</span>
                </div>                                         
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PrivacyContent',
        data() {
            return {}
        },
    }
</script>

<style scoped>
    .single-privacy h3
    {
        margin-bottom:15px;
    }
    .single-privacy{
        margin-bottom: 30px;
    }
</style>